import React, { useContext } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cs from "classnames";

import { DIRECTIONS, items } from "./utilities";
import classes from "./GlobalNavigation.module.scss";
import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";

const GlobalNavigation = ({ direction, onClick }) => {
  const { t } = useTranslation();
  const { common } = useContext(StoreContext);

  
  return (
    <ul data-test="te" className={cs(classes.container, classes[direction])}>
      {items.map((item) => (
        <li
          key={item.title.key}
          className={cs(classes.item, classes[item.color], {
            [classes.hidden]: item.hidden,
          })}
        >
          {item.handleRefresh && common.get("refreshMaorPage") ? (
            <a
              className={cs(classes.link, "heading-s-a d-block fw-700", {
                [classes.disabled]: item.disabled,
              })}
              href={item.href}
            >
              {t(item.title.key) ==
              "components.molecules.global_navigation.items.9" ? (
                ""
              ) : (
                <>{t(item.title.key)} </>
              )}
            </a>
          ) : (
            <NavLink
              activeClassName={classes.active}
              className={cs(
                classes.link,
                "heading-s-a d-block fw-700",
                item.color === "pledge" ? classes.maroonColor : "",
                item.color === "contest" ? classes.contestColor : "",
                {
                  [classes.disabled]: item.disabled,
                }
              )}
              exact={item.href != APPLICATION_ROUTES.LIBRARY}
              to={item.href}
              onClick={onClick}
            >
              {t(item.title.key) ==
              "components.molecules.global_navigation.items.9" ? (
                ""
              ) : (
                <>{t(item.title.key)} </>
              )}
            </NavLink>
          )}
          {t(item.title.key) ==
            "components.molecules.global_navigation.items.9" && (
            <a
              className={cs(
                classes.link,
                classes.purpleColor,
                "heading-s-a d-block fw-700",
                {
                  [classes.disabled]: item.disabled,
                }
              )}
              href="https://maor.shop/"
            >
              Store
              {/* {t(item.title.key)} */}
            </a>
          )}
        </li>
      ))}
    </ul>
  );
};

GlobalNavigation.propTypes = {
  direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
  onClick: PropTypes.func,
};

GlobalNavigation.defaultProps = {
  direction: DIRECTIONS.HORIZONTAL,
};

export default GlobalNavigation;
