/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import cs from "classnames";
import classes from "./CharacterForm.module.scss";
import TextAreaCharacter from "../../../../../../atoms/TextAreaCharacter";
import PlainInputCharacter from "../../../../../../atoms/PlainInputCharacter";
import RadioDotsCharacter from "../../../../../../atoms/RadioDotsCharacter";
import PropTypes from "prop-types";
import { CHARACTER_SUBMISSION_KEY } from "../../../../WritingCompetitionForm";
import { StoreContext } from "../../../../../../../contexts";
import useForm from "../../../../useForm";
import { Loader2 } from "lucide-react";
import { ACTIONS } from "../../../../../../../store";
import { observer } from "mobx-react-lite";
import Tooltip from "../../../../../../molecules/Tooltip";
import questionIcon from "../../../../../../../assets/images/question.png";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const CharacterForm = observer(({ onClose }) => {
  const {
    getCharacterNameInput,
    getCharacterGenderRadio,
    getCharacterAgeInput,
    getCharacterHeightRadio,
    getCharacterHeightInput,
    getCharacterLivingPlaceInput,
    getCharacterFavoriteMitzvahInput,
    getCharacterFavoriteFoodInput,
    getCharacterDislikesInput,
    getCharacterFavoriteActivityInput,
    getCharacterFavoriteYomTovInput,
    getCharacterHappinessInput,
    getCharacterGoodAtInput,
    getCharacterWearingInput,
    getCharacterFunFactInput,
    getCharacterFamilyInput,
    getCharacterAdditionalInfoInput,
    isNotValidCharacterSettings,
    triggerValidationsCharacter,
    submitStory,
  } = useForm();
  // Define constants at the top
  const characterName = getCharacterNameInput.value || "_____";
  const isNameEmpty = getCharacterNameInput.value === "";
  const [errorMessage, setErrorMessage] = useState(false);
  const [draftSavedMessage, setDraftSavedMessage] = useState(false); // New state

  const { common, writingcompetition } = useContext(StoreContext);

  const submissionData = common.get(CHARACTER_SUBMISSION_KEY);
  const characters = submissionData.characters ?? [];
  const currentCharacterIndex = common.get("currentCharacterIndex");
  const character = characters[currentCharacterIndex];
  let unsubmittedIndexes = common.get("unsubmittedIndexes");
  if (unsubmittedIndexes == null || unsubmittedIndexes == undefined) {
    unsubmittedIndexes = [];
  }
  
  useEffect(() => {
    if (character) {
      const inputMap = {
        name: getCharacterNameInput,
        age: getCharacterAgeInput,
        gender: getCharacterGenderRadio,
        height: character.height
          ? getCharacterHeightRadio.options.some((option) => option.value === character.height.toString())
            ? getCharacterHeightRadio
            : getCharacterHeightInput
          : getCharacterHeightInput,
        livingPlace: getCharacterLivingPlaceInput,
        favorite_mitzvah: getCharacterFavoriteMitzvahInput,
        favorite_food: getCharacterFavoriteFoodInput,
        dislikes: getCharacterDislikesInput,
        favorite_activity: getCharacterFavoriteActivityInput,
        favorite_yom_tov: getCharacterFavoriteYomTovInput,
        happiness: getCharacterHappinessInput,
        good_at: getCharacterGoodAtInput,
        wearing: getCharacterWearingInput,
        fun_fact: getCharacterFunFactInput,
        family: getCharacterFamilyInput,
        additional_info: getCharacterAdditionalInfoInput,
      };

      if (!character) {
        Object.entries(inputMap).forEach(([key, input]) => {
          input.onChange({ target: { value: "" }, touched: false });
          console.log(key, "input character");
        });
        return;
      }

      if (currentCharacterIndex !== -1) {
        Object.entries(inputMap).forEach(([key, input]) => {
          if (character.height) {
            if (
              key === "height" &&
              !getCharacterHeightRadio.options.some((option) => option.value === character.height.toString())
            ) {
              // If height doesn't match, set "Other" in getCharacterHeightRadio
              getCharacterHeightRadio.onChange({
                target: { value: "Other" },
                touched: false,
              });
            }
          }
          character[key] == undefined ? (character[key] = "") : character[key];
          if (character[key] !== undefined && input.onChange) {
            input.onChange({ target: { value: character[key] }, touched: false });
          }
        });
      }
    }
  }, [character]);

  useEffect(() => {
    if (writingcompetition[`success${ACTIONS.WRITING_COMPETITON.DRAFT_SUBMIT}`]) {
      setDraftSavedMessage(true);

      const timer = setTimeout(() => {
        setDraftSavedMessage(false);
      }, 3000);
      // Clean up the timer
      return () => clearTimeout(timer);
    }
  }, [writingcompetition[`success${ACTIONS.WRITING_COMPETITON.DRAFT_SUBMIT}`]]);

  const updatedCharacter = (status = "pending") => {
    const isExistingCharacter = currentCharacterIndex !== -1;

    const newCharacter = {
      name: getCharacterNameInput.value,
      age: getCharacterAgeInput.value,
      gender: getCharacterGenderRadio.value,
      height: getCharacterHeightInput.value,
      livingPlace: getCharacterLivingPlaceInput.value,
      favorite_mitzvah: getCharacterFavoriteMitzvahInput.value,
      favorite_food: getCharacterFavoriteFoodInput.value,
      dislikes: getCharacterDislikesInput.value,
      favorite_activity: getCharacterFavoriteActivityInput.value,
      favorite_yom_tov: getCharacterFavoriteYomTovInput.value,
      happiness: getCharacterHappinessInput.value,
      good_at: getCharacterGoodAtInput.value,
      wearing: getCharacterWearingInput.value,
      fun_fact: getCharacterFunFactInput.value,
      family: getCharacterFamilyInput.value,
      additional_info: getCharacterAdditionalInfoInput.value,
      status: status,
    };

    // Update or add a character
    const updatedCharacters = isExistingCharacter
      ? submissionData.characters.map((character, index) =>
          index === currentCharacterIndex
            ? { ...character, ...newCharacter } // Update existing character
            : character
        )
      : [...submissionData.characters, newCharacter]; // Add new character

    // Save updated characters to the submission data
    common.set(CHARACTER_SUBMISSION_KEY, {
      ...submissionData,
      characters: updatedCharacters,
    });
  };

  const saveDraft = () => {
    updatedCharacter();
    submitStory({ draftStatus: true });
  };

  const handleCharacterSettings = () => {
    if (isNotValidCharacterSettings) {
      triggerValidationsCharacter();
      setErrorMessage(true);
    } else {
      const newUnsubmittedIndexes = unsubmittedIndexes.filter((index) => index !== currentCharacterIndex);
      common.set("unsubmittedIndexes", newUnsubmittedIndexes);

      setErrorMessage(false);
      updatedCharacter("submitted");
      submitStory({
        characterSubmitStatus: true,
        onSucess: () => {
          onClose();
        },
      });
    }
  };

  useEffect(() => {
    if (!isNotValidCharacterSettings) {
      setErrorMessage(false);
    }
  }, [isNotValidCharacterSettings]);

  return (
    <div className={cs(classes.characterFormModal)}>
      <h2 className={cs(classes.heading, classes.grandstanderFamily)}>{character?.name || "Add Character"}</h2>
      <div className={cs(classes.characterFormContainer)}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 1019: 1, 1020: 2 }}>
          <Masonry columnsCount={2} gutter="30px">
            {/* Character Name */}
            <div className={cs(classes.characterFieldContainer, getCharacterNameInput.error && classes.errorbg)}>
              <label htmlFor="character-name-input">
                My Name: <span className={cs(classes.red)}> * </span>
              </label>
              <PlainInputCharacter
                error={getCharacterNameInput.error}
                id="character-name-input"
                label={getCharacterNameInput.label}
                value={getCharacterNameInput.value}
                onChange={getCharacterNameInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Favorite Mitzvah */}
            <div
              className={cs(
                classes.characterFieldContainer,
                getCharacterFavoriteMitzvahInput.error && classes.errorbg,
                isNameEmpty && classes.deactive
              )}
            >
              <label htmlFor="character-favorite-mitzvah-input">
                <span className={cs(classes.placeNameText)}>{characterName || ""}</span>
                ’s favorite mitzvah: <span className={cs(classes.red)}> * </span>
              </label>
              <PlainInputCharacter
                error={getCharacterFavoriteMitzvahInput.error}
                id="character-favorite-mitzvah-input"
                label={getCharacterFavoriteMitzvahInput.label}
                value={getCharacterFavoriteMitzvahInput.value}
                onChange={getCharacterFavoriteMitzvahInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Gender */}
            <div
              className={cs(
                classes.characterFieldContainer,
                getCharacterGenderRadio.error && classes.errorbg,
                isNameEmpty && classes.deactive
              )}
            >
              <label className={cs(classes.hrGrey)} htmlFor="character-gender-input">
                <span className={cs(classes.placeNameText)}>{characterName || ""}&nbsp;</span> is a:{" "}
                <span className={cs(classes.red)}> * </span>
              </label>
              <RadioDotsCharacter
                vertical
                items={getCharacterGenderRadio.options}
                value={getCharacterGenderRadio.value}
                onChange={getCharacterGenderRadio.onChange}
                error={getCharacterGenderRadio.error}
                orangeBorderRadio={true}
              />
            </div>

            {/* Character Favorite Food */}
            <div className={cs(classes.characterFieldContainer, isNameEmpty && classes.deactive)}>
              <label htmlFor="character-favorite-food-input">
                What’s <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}</span>’s favorite food?
              </label>
              <PlainInputCharacter
                error={getCharacterFavoriteFoodInput.error}
                id="character-favorite-food-input"
                label={getCharacterFavoriteFoodInput.label}
                value={getCharacterFavoriteFoodInput.value}
                onChange={getCharacterFavoriteFoodInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Age */}
            <div
              className={cs(
                classes.characterFieldContainer,
                getCharacterAgeInput.error && classes.errorbg,
                isNameEmpty && classes.deactive
              )}
            >
              <label htmlFor="character-age-input">
                How old is <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}</span>?{" "}
                <span className={cs(classes.red)}> * </span>
              </label>
              <PlainInputCharacter
                error={getCharacterAgeInput.error}
                id="character-age-input"
                label={getCharacterAgeInput.label}
                value={getCharacterAgeInput.value}
                onChange={getCharacterAgeInput.onChange}
                placeHolder="Short Answer text"
                type="number"
              />
            </div>

            {/* Character Dislikes */}
            <div className={cs(classes.characterFieldContainer, isNameEmpty && classes.deactive)}>
              <label htmlFor="character-dislikes-input">
                What does <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}&nbsp;</span> dislike?
                <div style={{ marginTop: "6px" }} className={cs(classes.tooltip, "ml-auto as-start c-grey-dark")}>
                  <Tooltip
                    message={`Example: ${
                      characterName || ""
                    } "doesn’t like it when someone interrupts him while he’s telling a story. He also doesn’t like loud noises."`}
                    color="yellow"
                  >
                    <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} />
                  </Tooltip>
                </div>
              </label>
              <PlainInputCharacter
                error={getCharacterDislikesInput.error}
                id="character-dislikes-input"
                label={getCharacterDislikesInput.label}
                value={getCharacterDislikesInput.value}
                onChange={getCharacterDislikesInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Height */}
            <div
              className={cs(
                classes.characterFieldContainer,
                getCharacterHeightInput.error && classes.errorbg,
                isNameEmpty && classes.deactive
              )}
            >
              <label className={cs(classes.hrGrey)} htmlFor="character-height-input">
                Character Height: <span className={cs(classes.red)}> * </span>
              </label>
              <RadioDotsCharacter
                vertical
                items={getCharacterHeightRadio.options}
                value={getCharacterHeightRadio.value}
                onChange={getCharacterHeightRadio.onChange}
                error={getCharacterHeightRadio.error}
                orangeBorderRadio={true}
              />
              {getCharacterHeightRadio.value === "Other" && (
                <div className={cs(classes.radioInput)}>
                  <PlainInputCharacter
                    error={getCharacterHeightInput.error}
                    id="character-height-input"
                    label={getCharacterHeightInput.label}
                    value={getCharacterHeightInput.value}
                    onChange={getCharacterHeightInput.onChange}
                    placeHolder="Short Answer text"
                  />
                </div>
              )}
            </div>

            {/* Character Favorite Activity */}
            <div
              className={cs(
                classes.characterFieldContainer,
                getCharacterFavoriteActivityInput.error && classes.errorbg,
                isNameEmpty && classes.deactive
              )}
            >
              <label htmlFor="character-favorite-activity-input">
                What’s <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}</span>’s favorite
                activity? <span className={cs(classes.red)}> * </span>
                <div style={{ marginTop: "6px" }} className={cs(classes.tooltip, "ml-auto as-start c-grey-dark")}>
                  <Tooltip
                    message={`Example: ${
                      characterName || ""
                    } "loves building Lego towers, riding his bike, and drawing pictures of his favorite Maor characters."`}
                    color="yellow"
                  >
                    <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} />
                  </Tooltip>
                </div>
              </label>
              <PlainInputCharacter
                error={getCharacterFavoriteActivityInput.error}
                id="character-favorite-activity-input"
                label={getCharacterFavoriteActivityInput.label}
                value={getCharacterFavoriteActivityInput.value}
                onChange={getCharacterFavoriteActivityInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Wearing */}
            <div
              className={cs(
                classes.characterFieldContainer,
                getCharacterWearingInput.error && classes.errorbg,
                isNameEmpty && classes.deactive
              )}
            >
              <label htmlFor="character-wearing-input">
                What’s <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}&nbsp;</span> wearing?{" "}
                <span className={cs(classes.red)}> * </span>
                <div style={{ marginTop: "6px" }} className={cs(classes.tooltip, "ml-auto as-start c-grey-dark")}>
                  <Tooltip
                    message={`Example: ${
                      characterName || ""
                    } "is wearing a blue yarmulkah, a white shirt, and black pants with suspenders. He also has a colorful striped scarf around his neck."`}
                    color="yellow"
                  >
                    <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} />
                  </Tooltip>
                </div>
              </label>
              <PlainInputCharacter
                error={getCharacterWearingInput.error}
                id="character-wearing-input"
                label={getCharacterWearingInput.label}
                value={getCharacterWearingInput.value}
                onChange={getCharacterWearingInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Favorite Yom Tov */}
            <div
              className={cs(
                classes.characterFieldContainer,
                getCharacterFavoriteYomTovInput.error && classes.errorbg,
                isNameEmpty && classes.deactive
              )}
            >
              <label htmlFor="character-favorite-yom-tov-input">
                What’s <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}</span>’s favorite Yom
                Tov? <span className={cs(classes.red)}> * </span>
              </label>
              <PlainInputCharacter
                error={getCharacterFavoriteYomTovInput.error}
                id="character-favorite-yom-tov-input"
                label={getCharacterFavoriteYomTovInput.label}
                value={getCharacterFavoriteYomTovInput.value}
                onChange={getCharacterFavoriteYomTovInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Living Place */}
            <div
              className={cs(
                classes.characterFieldContainer,
                getCharacterLivingPlaceInput.error && classes.errorbg,
                isNameEmpty && classes.deactive
              )}
            >
              <label htmlFor="character-living-place-input">
                Where does <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}&nbsp;</span> live?{" "}
                <span className={cs(classes.red)}> * </span>
              </label>
              <PlainInputCharacter
                error={getCharacterLivingPlaceInput.error}
                id="character-living-place-input"
                label={getCharacterLivingPlaceInput.label}
                value={getCharacterLivingPlaceInput.value}
                onChange={getCharacterLivingPlaceInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Happiness */}
            <div className={cs(classes.characterFieldContainer, isNameEmpty && classes.deactive)}>
              <label htmlFor="character-happiness-input">
                What makes <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}&nbsp;</span> happy?
                <div style={{ marginTop: "6px" }} className={cs(classes.tooltip, "ml-auto as-start c-grey-dark")}>
                  <Tooltip
                    message={`Example: ${
                      characterName || ""
                    } "feels happy when he helps his little sister. He also loves baking challah with Mommy. "`}
                    color="yellow"
                  >
                    <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} />
                  </Tooltip>
                </div>
              </label>
              <PlainInputCharacter
                error={getCharacterHappinessInput.error}
                id="character-happiness-input"
                label={getCharacterHappinessInput.label}
                value={getCharacterHappinessInput.value}
                onChange={getCharacterHappinessInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Good At */}
            <div
              className={cs(
                classes.characterFieldContainer,
                getCharacterGoodAtInput.error && classes.errorbg,
                isNameEmpty && classes.deactive
              )}
            >
              <label htmlFor="character-good-at-input">
                What is <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}&nbsp;</span> good at?{" "}
                <span className={cs(classes.red)}> * </span>
              </label>
              <PlainInputCharacter
                error={getCharacterGoodAtInput.error}
                id="character-good-at-input"
                label={getCharacterGoodAtInput.label}
                value={getCharacterGoodAtInput.value}
                onChange={getCharacterGoodAtInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Fun Fact */}
            <div className={cs(classes.characterFieldContainer, isNameEmpty && classes.deactive)}>
              <label htmlFor="character-fun-fact-input">
                What’s <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}</span>’s fun fact?
                <div style={{ marginTop: "6px" }} className={cs(classes.tooltip, "ml-auto as-start c-grey-dark")}>
                  <Tooltip
                    message={`Example:${
                      characterName || ""
                    }  can juggle three balls and can recite all 12 Pesukim by heart in a super-fast voice! Last year, he won Chidon and got his plaque on stage."`}
                    color="yellow"
                  >
                    <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} />
                  </Tooltip>
                </div>
              </label>
              <PlainInputCharacter
                error={getCharacterFunFactInput.error}
                id="character-fun-fact-input"
                label={getCharacterFunFactInput.label}
                value={getCharacterFunFactInput.value}
                onChange={getCharacterFunFactInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Family */}
            <div className={cs(classes.characterFieldContainer, isNameEmpty && classes.deactive)}>
              <label htmlFor="character-family-input">
                Who’s in <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}</span>’s family?
                <div style={{ marginTop: "6px" }} className={cs(classes.tooltip, "ml-auto as-start c-grey-dark")}>
                  <Tooltip
                    message={`Example: ${
                      characterName || ""
                    } "has a big, happy family. He has two older brothers, a younger sister, and a baby cousin he loves to play with. His parents are super kind and always cheer him on to do mitzvot."`}
                    color="yellow"
                  >
                    <img src={questionIcon} alt="questionIcon" className={cs(classes.questionIcon)} />
                  </Tooltip>
                </div>
              </label>
              <PlainInputCharacter
                error={getCharacterFamilyInput.error}
                id="character-family-input"
                label={getCharacterFamilyInput.label}
                value={getCharacterFamilyInput.value}
                onChange={getCharacterFamilyInput.onChange}
                placeHolder="Short Answer text"
              />
            </div>

            {/* Character Additional Info */}
            <div className={cs(classes.characterFieldContainer, isNameEmpty && classes.deactive)}>
              <label htmlFor="character-additional-info-input">
                Additional Info about{" "}
                <span className={cs(classes.placeNameText)}>&nbsp;{characterName || ""}&nbsp;</span>
              </label>
              <TextAreaCharacter
                error={getCharacterAdditionalInfoInput.error}
                id="character-additional-info-input"
                value={getCharacterAdditionalInfoInput.value}
                onChange={getCharacterAdditionalInfoInput.onChange}
                placeHolder="Long Answer text"
              />
            </div>
          </Masonry>
        </ResponsiveMasonry>
      </div>

      {errorMessage ? <div className={cs(classes.errorText)}>Please fill in the missing fields. </div> : ""}

      <div className={cs(classes.formButtons)}>
        {character?.status !== "submitted" && (
          <button
            className={cs(classes.submitScriptButton, classes.saveDraft, classes.grandstanderFamily)}
            onClick={saveDraft}
          >
            {writingcompetition[`isLoading${ACTIONS.WRITING_COMPETITON.DRAFT_SUBMIT}`] ? (
              <Loader2 className={cs(classes.loader)} />
            ) : (
              "Save Draft"
            )}
          </button>
        )}

        <button
          className={cs(classes.submitScriptButton, classes.grandstanderFamily, errorMessage && classes.errorButtonbg)}
          onClick={handleCharacterSettings}
        >
          {writingcompetition[`isLoading${ACTIONS.WRITING_COMPETITON.CHARACTER_SUBMIT}`] ? (
            <Loader2 className={cs(classes.loader)} />
          ) : (
            <>{character?.status === "submitted" ? "Update  Character" : "Submit Character"}</>
          )}
        </button>
      </div>

      {draftSavedMessage && character?.status !== "submitted" && (
        <p className={cs(classes.draftSavedMessage)}>Draft Saved</p>
      )}
    </div>
  );
});

CharacterForm.propTypes = {
  onClose: PropTypes.func,
};

export default CharacterForm;
